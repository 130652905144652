.container {
  margin: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.accounts-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.accounts-table th, .accounts-table td {
  padding: 12px 15px;
  text-align: left;
}

.accounts-table thead {
  background-color: #f4f6f8;
  color: #333;
  font-weight: 600;
}

.accounts-table tbody tr {
  background-color: #ffffff;
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.3s ease;
}

.accounts-table tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.accounts-table tbody tr:hover {
  background-color: #edf2f7;
}

.accounts-table th {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  border-bottom: 2px solid #e2e8f0;
}

.accounts-table td {
  font-size: 14px;
  color: #555;
}

.accounts-table tbody tr:last-child {
  border-bottom: none;
}

/* Estilo para los filtros */
input {
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
